<template>
  <v-app>
    <div class="card">
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4> Books returned</h4>
            <div class="breadcrumb-sub-header">
              <router-link to="/dashboard">Dashboard</router-link>
              \ Books returned
            </div>
          </div>

        </div>
      </div>
      <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <div class="row">
            <v-col cols="12" md="4">
              <v-text-field v-on:keyup.enter="getAllReturnedBooks()" autofocus outlined dense v-model="search.barcode"
                clearable label="Student Id Card No"></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-on:keyup.enter="getAllReturnedBooks()" clearable outlined dense v-model="search.name"
                label="Symbol no., name, email"></v-text-field>
            </v-col>
            
            <v-col cols="12" md="4">
              <v-text-field v-on:keyup.enter="getAllReturnedBooks()" clearable outlined dense v-model="search.book_no"
                label="Book no."></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select v-on:keyup.enter="getAllReturnedBooks()" clearable v-model="search.filter_by" :items="filter_type"
                item-text="title" item-value="value" outlined dense label="Filter by"></v-select>
            </v-col>
            
            <v-col cols="12" md="3" v-if="search.filter_by == 'custom'">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="search.dates" label="Select by dates" prepend-icon="mdi-calendar" readonly
                    v-on:keyup.enter="getAllReturnedBooks()" v-bind="attrs" v-on="on" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="search.dates" range></v-date-picker>

              </v-menu>
            </v-col>

            <v-col cols="12" md="2" v-if="search.filter_by == 'custom'"></v-col>
            <v-col cols="12" md="5" v-if="search.filter_by != 'custom'"></v-col>

            <v-col cols="12" md="4" class="text-right">
              <v-btn @click.prevent="getAllReturnedBooks()" class="btn btn-primary btn-search ">
                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </div>
        </div>
        <div class="">
          <v-skeleton-loader type="table-thead" v-if="loading">
          </v-skeleton-loader>

          <v-skeleton-loader v-if="loading" type="table-row-divider@25">
          </v-skeleton-loader>
          <table class="table">
            <thead>
              <tr class="px-3">
                <th class="px-3 wrap-column">#</th>
                <th class="px-3 wrap-column">Book title</th>
                <th class="px-3 wrap-column"></th>
                <th class="px-3 wrap-column">User detail</th>
                <th>Dates</th>
              </tr>

            </thead>
            <tbody v-if="return_books.length > 0">
              <tr v-for="(iss, i) of return_books" :key="i">
                <td class="px-3 wrap-column">
                  <div v-if="iss.book_no" class="text-secondary">
                    {{ iss.book_no }}
                  </div>
                  <div v-if="iss.book_accession_no" class="text-secondary">
                    {{ iss.book_accession_no }}
                  </div>

                </td>
                <td class="px-3 wrap-column">
                  <span class="font-weight-bold">
                    {{ iss.book_title }}
                  </span>
                  <div class="text-secondary">
                    <span class="font-weight-medium">{{ iss.book_author }}</span>
                  </div>
                </td>

                <td class="px-3 wrap-column">
                  <img class="cursor-pointer img-thumbnail" @click="redirectTODetail(iss.user_id)" style="height: 80px"
                    v-if="iss.user_image" :src="iss.user_image" alt="">
                  <span v-else>-</span>
                </td>

                <td style="cursor:pointer; color:blue" class="px-3 wrap-column" @click="redirectTODetail(iss.user_id)">
                  <div class="text-secondary">
                    <a @click="redirectTODetail(iss.user_id, iss.user_type)">
                      {{ iss.user_id_card_no }} - {{ iss.personal_id }}
                    </a>
                  </div>
                  <div class="text-secondary">
                    {{ iss.user_full_name }}
                  </div>
                  <div class="text-secondary">
                    {{ iss.program }}
                  </div>
                  <div class="text-secondary">
                    {{ iss.grade }} - {{ iss.class }}
                  </div>
                </td>
                <td class="px-3 wrap-column">
                  <div class="text-secondary">
                    <strong>Issued date: </strong> {{ iss.issue_date_text ? iss.issue_date_text : '-'
                    }}
                  </div>
                  <div class="text-secondary mt-3">
                    <strong>Returned date: </strong>{{ iss.renew_date_text ? iss.renew_date_text : '-'
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <p class="text-center">
                    <strong>Data not available</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination v-if="return_books.length > 0" class="pull-right mt-7" @input="getAllReturnedBooks" v-model="page"
            :total-rows="total" :per-page="perPage" first-number last-number :loading="loading"></b-pagination>
        </div>



      </div>
    </div>
  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
const book = new BookService;
export default {
  data() {
    return {
      return_books: [],
      loading: false,
      page: 1,
      total: null,
      perPage: null,
      filter_type: [
        { 'title': 'Today', 'value': 'today' },
        { 'title': 'Yesterday', 'value': 'yesterday' },
        { 'title': 'Current week', 'value': 'this_week' },
        { 'title': 'Last week', 'value': 'last_week' },
        { 'title': 'This month', 'value': 'this_month' },
        { 'title': 'Custom', 'value': 'custom' },
        { 'title': 'All', 'value': 'all' },
      ],
      search: {
        status: null,
        dates: [],
        name: null,
        book_no: null,
        filter_by: 'all',
        type: 'returned-list'
      }
    }
  },
  methods: {
    getAllReturnedBooks() {
      this.loading = true
      book
        .getAllIssueBook(this.search, this.page)
        .then(response => {
          this.return_books = response.data.data
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loading = false;
        });
    },
    redirectTODetail(userId, userType) {
      if(this.checkIsAccessible('user', 'list')) {
        this.$router.push({
          name: "students-summary",
          params: { id: userId, type: userType[0] }
        });
      }
    },
  },
  mounted() {
    this.getAllReturnedBooks();
  }
}
</script>